<template>
    <div>
        <van-popup
            class="city-popup"
            v-model="popShow2"
            position="bottom"
            
            @click-overlay="cancelFun"
        
        >
            <div>
                <div class="f16 fw7 pl12 mt25">选择{{ tyoeObj[type] }}</div>
                <div class="city-list-box">
                    <div class="city-list-1">
                        <div
                            key="all"
                            :class="areaId==='all' ? 'list1-item-check' : ''"
                            @click="choseList1Item('all')"
                        >
                            <!--                            :class="item.check ? 'list1-item-check' : ''"-->
                            全部
                        </div>
                        <div
                            v-for="(item, index) in data"
                            :key="item.label"
                            :class="item.check ? 'list1-item-check' : ''"
                            @click="choseList1Item(index)"
                        >
                            {{ item.label }}
                        </div>
                    </div>
                    <div class="city-list-2">
                        <div
                            key="allvenue"
                            :class="allVenue ? 'list1-item-check' : ''"
                            @click="choseList2Item('all')"
                            v-if="!single"
                        >
                            全部门店
                        </div>
                        <div
                            v-for="(item, index) in items"
                            :key="item.label"
                            @click="choseList2Item(index)"
                        >
                            {{ item.label }}
                            <img
                                class="city-check-img"
                                v-show="item.check"
                                src="https://wxs.chaolu.com.cn/images/appWeb/coachingModule/workbench/city-chose-check.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="city-select-boxs">
                    <div class="city-select-btns">
                        <div v-if="!single" class="city-reset-btn" @click="resetFun">重置</div>
                        <div v-if="single" class="city-chose-btn" @click="searchFun">{{ btnText }}</div>
                        <div v-else class="city-chose-btn" @click="searchFun">
                            查看{{ venueId.length > 0 ? venueId.length + '个' : '全部' }}门店
                        </div>
                        <!--                        <div class="city-chose-btn" @click="searchFun" v-else>-->
                        <!--                            查看{{venueId.length}}个门店-->
                        <!--                        </div>-->
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import {
    Popup, PullRefresh,
} from 'vant';

export default {
    name: 'clCityVenue',
    // props: ["data", "popShow", "type", 'chose-item', 'single'], // single 是否单选
    props: {
        data: {
            type: Array,
            default() {return []}
        },
        choseItem: {
            type: Object,
            default() {return {}}
        },
        popShow: {
            type: Boolean,
            default: false
        },
        single: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'venue'
        },
        btnText: {
            type: String,
            default: '查看门店'
        }
    },
    components: {
        "van-popup": Popup,
    },
    data: function () {
        return {
            tyoeObj: {
                city: '城市',
                venue: '门店'
            },
            provinceId: '',
            cityId: '',
            areaId: '',
            venueId: [],
            items: [],
            listName: '',
            total: null,
            provinceName: '',
            cityName: '',
            areaName: '',
            venueName: '',
            popShow2: false,
        };
    },
    computed: {
        allVenue() {
            return !this.items.some(x => x.check === false)
        }
    },
    watch: {
        data: {
            handler: "getList",
            // deep: true,
            // immediate: true,
        },
        popShow(val) {
            this.popShow2 = this.popShow
            // if (!val){
            //     this.$emit('update:popShow', val)
            // }
        },
        popShow2(val) {
            if (!val) {
                this.$emit('update:popShow', val)
            } else {
                this.getList(this.data)
            }
        }
    },
    methods: {
        getList(val) {
            console.log('getList')
            if (this.choseItem !== undefined && val.length !== 0) {
                if (this.type === 'venue') {
                    var {areaId, venueId} = this.choseItem
                    console.log('this.choseItem', this.choseItem)
                    this.areaId = areaId
                    this.venueId = venueId || []
                    this.venueId = JSON.parse(JSON.stringify(this.venueId))
                    this.areaChoseFun(areaId)
                    this.venueChoseFun(venueId)
                    var choseName = this.venueId ? this.venueName : ''
                    // this.$emit("popfun", false,{areaName:this.areaName,venueName:this.venueName},choseName)
                }
            }
        },
        provinceChoseFun(provinceId) {
            provinceId = provinceId || undefined
            for (var i = 0; i < this.data.length; i++) {
                var item = this.data[i]
                if (provinceId === item.id) {
                    item.check = true
                    this.items = item.children
                    this.provinceName = item.label
                    this.listName = item.label
                    this.total = 0
                } else {
                    item.check = false
                }
            }
        },
        cityChoseFun(cityId) {
            console.log('cityChoseFun', cityId)
            cityId = cityId || undefined
            for (var i = 0; i < this.items.length; i++) {
                var item = this.items[i]
                if (cityId === item.id) {
                    console.log('item', item.label)
                    item.check = true
                    this.cityName = item.label
                } else {
                    item.check = false
                }
            }
            if (cityId) {
                this.total = 1
            }
        },
        areaChoseFun(areaId) {
            areaId = areaId || undefined
            if (!areaId) {
                this.choseList1Item('all')
                return
            }
            
            for (var i = 0; i < this.data.length; i++) {
                var item = this.data[i]
                if (areaId === item.id) {
                    item.check = true
                    this.items = item.children
                    this.areaName = item.label
                    this.listName = item.label
                    // this.total = item.areaCount
                } else {
                    item.check = false
                }
            }
        },
        venueChoseFun(venueId) {
            console.log(venueId)
            venueId = venueId || []
            for (var i = 0; i < this.items.length; i++) {
                var item = this.items[i]
                if (venueId.some(x => x == item.venueId)) {
                    item.check = true
                    // this.venueName =item.label
                    if(this.single){
                        this.venueName = item.name
                    }
                } else {
                    item.check = false
                }
            }
            if (venueId) {
                this.total = 1
            }
        },
         choseList1Item(index) {
            //一级选择框
            if (index !== 'all') {
                for (var i = 0; i < this.data.length; i++) {
                    this.data[i].check = false;
                }
                this.data[index].check = true;
                this.total = this.data[index].areaCount
                this.listName = this.data[index].name
                if (this.type === 'city') {
                    this.provinceId = this.data[index].cityId || ''
                    this.provinceName = this.data[index].name
                    this.cityId = ''
                    this.cityName = '全部城市'
                } else if (this.type === 'venue') {
                    this.areaId = this.data[index].id || ''
                    this.areaName = this.data[index].label
                    // this.venueId = ''
                    // this.venueName = '全部门店'
                }
                this.items = this.data[index].children
            } else {
                console.log('choseList1Item all')
                for (let i = 0; i < this.data.length; i++) {
                    this.data[i].check = false;
                }
                this.areaId = 'all'
                this.items = []
                this.data.forEach((val) => {
                    this.items.push(...val.children)
                })
            }
            
            // for (var j = 0; j < this.items.length; j++) {
            //     this.items[j].check = false;
            // }
            // this.items[0].check = true;
            this.$forceUpdate();
        },
        choseList2Item(index) {
            // 二级选择框 如果要多选的话。在这里处理！！！！
            // var i = 0;
            // for (i; i < this.items.length; i++) {
            //     this.items[i].check = false;
            // }
            if (index === 'all') {
                if (this.items.some(x => x.check === false)) {
                    this.items = this.items.map(x => {
                        x.check = true
                        return x
                    })
                } else {
                    this.items = this.items.map(x => {
                        x.check = false
                        return x
                    })
                }
            }else if(this.single) {
                this.venueId = []
                this.data.forEach((value, index) => {
                    value.children.forEach((value2) => {
                        value2.check = false
                    })
                })
                this.items[index].check = !this.items[index].check
            }  else {
                this.items[index].check = !this.items[index].check
            }
            this.venueId = []
            this.data.forEach((value, index) => {
                value.children.forEach((value2) => {
                    if (value2.check) {
                        this.venueId.push(value2.venueId)
                        if(this.single){
                            this.venueName = value2.name
                        }
                    }
                })
            })
            this.$forceUpdate();
        },
        cancelFun() {
            console.log('cancelFun')
            this.$emit("popfun", false);
            // this.getList(this.data)
            // this.searchFun()
        },
        resetFun() {
            this.choseList1Item(0);
            this.choseList2Item(0);
            var param = {}, choseName = ''
            if (this.type === 'city') {
                param = {
                    provinceId: '',
                    cityId: '',
                    provinceName: '',
                    cityName: '',
                    areaId: '',
                    areaName: '',
                    venueId: '',
                    venueName: ''
                }
                choseName = '全部城市' + '(' + this.data[0].areaCount + ')'
            } else {
                param = {areaId: '', areaName: '', venueId: [], venueName: ''}
                choseName = '全部门店'
                this.venueId = []
                this.data.forEach((value, index) => {
                    value.children.forEach((value2) => {
                        value2.check = false
                    })
                })
            }
            
            this.$emit("popfun", false, param, choseName)
        },
        searchFun() {
            var param = {}, choseName = ''
            
            param = {areaId: this.areaId, areaName: this.areaName, venueId: this.venueId, venueName: this.venueName}
            // choseName = (this.venueId?this.venueName:'')
            choseName = (this.venueId.length > 0 ? '选中门店' + '(' + this.venueId.length + ')' : '全部门店')
            console.log('this.venueId.', this.venueId)
            // 传参数给父级组件显示
            this.$emit("popfun", false, param, choseName)
        },
    },
};
</script>

<style scoped>
.f16 {
    font-size: 1rem;
}

.fw7 {
    font-weight: 700;
}

.mt25 {
    margin-top: 1.5625rem
}

.pl12 {
    padding-left: 0.75rem /* 12/16 */
}

.city-popup {
    min-height: 31.25rem;
    border-radius: 0.75rem 0.75rem 0 0;
    /*padding-top: 1.625rem  !* 26/16 *!;*/
    box-sizing: border-box;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
}

.van-popup__close-icon {
    color: #3c454e;
}

.van-popup__close-icon--top-right {
    top: 1.625rem /* 26/16 */;
    right: 0.75rem /* 12/16 */;
}

.city-list-box {
    width: 100%;
    box-sizing: border-box;
    padding: 0rem /* 0/16 */ 0.75rem /* 12/16 */;
    margin-top: 0.9375rem /* 15/16 */;
    display: flex;
}

.city-list-2::-webkit-scrollbar,
.city-list-1::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}

.city-list-1 {
    margin-right: 1.0625rem /* 17/16 */;
    max-height: 23rem /* 368/16 */;
    height: 23rem /* 368/16 */;
    overflow: scroll;
}

.city-list-1 > div {
    width: 6.625rem /* 106/16 */;
    height: 2.625rem /* 42/16 */;
    font-size: 0.875rem /* 14/16 */;
    margin-bottom: 0.625rem /* 10/16 */;
    background: #f5f5f5;
    border-radius: 0.25rem /* 4/16 */;
    line-height: 2.625rem /* 42/16 */;
    text-align: center;
    overflow: hidden;
}

.list1-item-check {
    background: #ffde00 !important;
    font-weight: 700;
}

.city-list-2 {
    flex: 1;
    max-height: 23rem /* 368/16 */;
    height: 23rem /* 368/16 */;
    overflow: scroll;
}

.city-list-2 > div {
    width: 100%;
    height: 3.5rem /* 56/16 */;
    line-height: 3.5rem /* 56/16 */;
    font-size: 0.875rem /* 14/16 */;
    padding-left: 0.75rem /* 12/16 */;
    box-sizing: border-box;
    background: #f5f5f5;
    border-radius: 0.25rem /* 4/16 */;
    margin-bottom: 0.625rem /* 10/16 */;
    position: relative;
}

.city-check-img {
    width: 1.25rem /* 20/16 */;
    position: absolute;
    top: 1.125rem /* 18/16 */;
    right: 0.625rem /* 10/16 */;
}

.city-select-boxs {
    width: 100%;
    height: 4.5rem /* 72/16 */;
    /*background: pink;*/
    position: relative;
}

.city-select-btns {
    height: 4.5rem /* 72/16 */;
    padding: 0.875rem /* 14/16 */ 0.75rem /* 12/16 */;
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    bottom: 0px;
    display: flex;
    box-shadow: 0px -0.0625rem /* -1/16 */ 0.375rem /* 6/16 */ -0.125rem /* -2/16 */ rgba(204, 212, 241, 0.5);
}

.city-reset-btn {
    width: 6.75rem /* 108/16 */;
    height: 2.75rem /* 44/16 */;
    margin-right: 0.875rem /* 14/16 */;
    border: 1px solid #dddddd;
    border-radius: 0.25rem /* 4/16 */;
    text-align: center;
    line-height: 2.625rem /* 42/16 */;
    font-size: 0.875rem /* 14/16 */;
    box-sizing: border-box;
}

.city-chose-btn {
    background: #ffde00;
    border-radius: 0.25rem /* 4/16 */;
    flex: 1;
    border: 1px solid #ffde00;
    text-align: center;
    line-height: 2.625rem /* 42/16 */;
    font-size: 0.875rem /* 14/16 */;
    font-weight: 700;
}
</style>
